.ant-btn-background-ghost {
  &.ant-btn-default:hover {
    color: #ffffff !important;
    border-color: #a3a9b9 !important;
  }
  &.ant-btn-dangerous:hover {
    color: #ff4d4f !important;
    border-color: #f98989 !important;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 36px;
}

.ant-btn-circle {
  border-radius: 50% !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}
