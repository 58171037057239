@import '~antd/lib/menu/style/index';

.sidebarLayout {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.confirmation-popover {
  width: 220px !important;
}

.cropwiseLogo {
  text-align: center;
  color: #ffffff;
  height: 60px;
  padding-top: 7px;
}
.cropwiseLogo img {
  max-height: 100%;
}

.sidebarSubmenuItem {
  padding-left: 16px;
}

.sidebarFooter {
  color: #696f88;
  text-align: justify;
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  padding-left: 24px;
}

.submenuName {
  font-size: 14px;
  position: static;
  height: 30px;
  line-height: 33px;
  margin: 0px 10px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.submenuIcon {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  fill: #696f88;
}

.submenuElementIcon {
  float: left;
  padding-right: 10px;
}

.sidebarContent {
  padding: 24;
  margin: 0;
  min-height: 280;
  text-align: center;
  background: #ffffff;
}

.sidebarLayoutContent {
  padding: 15px;
}

.triggerExpandButton {
  margin-top: 3px;
  fill: #696f88;
}

.triggerCloseButton {
  float: right;
  margin-top: 3px;
  margin-right: 20px;
  fill: #696f88;
}
.sibebarSyngentDigitalLogo {
  position: fixed;
  bottom: 0;
  height: 50px;
  z-index: 2;
  padding-left: 10px;
}
.ant-menu-dark {
  background-color: #232630 !important;
}

.ant-layout-sider-dark {
  background-color: #232630 !important;
}

.ant-layout-sider-dark .ant-layout-sider-trigger {
  background-color: #14151c !important;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  background-color: #f3f4f6 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #eaf6ff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
  content: '';
  width: 4px;
  height: 32px;
  background: #0071cd;
  position: absolute;
  top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-item-selected {
  color: #0071cd;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #14151c !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
  content: '';
  width: 4px;
  height: 32px;
  background: #ffffff;
  position: absolute;
  top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #232630;
}

ul.ant-menu-inline-collapsed {
  width: 64px !important;
}
.ant-menu-item {
  padding: 3px 20px !important;
}
.ant-popconfirm {
  height: 100px !important;
  .ant-popover-inner-content {
    padding: 16px;
    width: 280px;
    border-radius: 2px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-message-title {
    padding-bottom: 11px;
  }
  .ant-popover-message-title {
    color: #14151c;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.0075em;
    text-align: left;
    padding-left: 29px;
  }
  .ant-btn-primary {
    background-color: #14803c;
    border-color: #14803c;
    &:hover {
      color: #ffffff;
      background-color: #19a04b;
      border-color: #19a04b;
    }
  }
  .ant-btn:not(.ant-btn-primary) {
    background: #ffffff;
    border: 1px solid #c2c7d0;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      color: #14151c;
    }
  }
}
.sidebar-tooltip-card {
  left: 13% !important;
}
