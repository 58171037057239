.page-container .quota-container {
  max-width: 100% !important;
}
.all-fields-list-container,
.all-fields-list-header {
  .ant-list-item {
    .list-item {
      cursor: pointer;

      .ant-list-item-meta-avatar {
        display: flex;
        background: none;

        .ant-checkbox-wrapper {
          margin-right: 16px;
        }
      }

      .edit-field-icon {
        display: inline-block;
        position: relative;
        top: -4px;
      }
    }
    .menu-group {
      margin-right: 0px;
      .ant-btn {
        padding: 0 6px;
      }
    }
  }
  .list-item-hovered {
    .ant-checkbox {
      border: 1px solid #00b5d8;
    }
  }
  .ant-list-item.selected {
    background: #f3f4f6;
  }

  .noResultFoundDiv {
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.all-fields-list-header {
  .ant-list-item {
    .list-item {
      .ant-list-item-meta-avatar {
        margin-right: 0px;
        background: none;
      }
    }
  }

  .header-title-container {
    display: flex;
    align-items: center;
    align-content: space-around;

    .title {
      margin-right: 10px;
      font-weight: bold;
    }

    .sort-icon {
      position: relative;
      right: 0px;
    }
  }

  .inline {
    display: inline-block;
  }

  .ant-list-item.selected {
    background: #f3f4f6;
  }
}

.confirm-field-edit-modal {
  .confirm-field-edit-modal-content {
    display: flex;

    .left {
      margin-right: 9px;
    }

    .right {
      .confirm-field-edit-modal-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #14151c;
      }
    }
  }

  .ant-modal-footer {
    padding-top: 0;

    .ant-btn:first-child {
      border: none;
    }

    .ant-btn-primary,
    .ant-btn-primary:hover {
      background: #14803c;
      border-radius: 4px;
      color: white;
    }
  }

  .ant-btn-primary {
    background: #0092e4 !important;
    border-color: #0092e4 !important;
  }

  .ant-btn-default {
    &:hover,
    &:focus {
      color: #0092e4;
      border-color: #0092e4;
    }
  }
}

.all-fields-delete-confirm-modal {
  .ant-btn-dangerous {
    background: #cf3537;
    color: white;
  }
  .all-fields-delete-confirm-modal-content {
    display: flex;

    .left {
      margin-right: 18px;
    }

    .right {
      .all-fields-delete-confirm-modal-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #14151c;
        margin-bottom: 8px;
      }

      .content {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #14151c;

        .fields-list {
          margin: 25px 0;
          padding-left: 15px;
        }
      }

      .all-fields-delete-disclaimer {
        font-weight: 800;
      }
    }
  }

  .ant-modal-footer {
    padding-top: 0;
    border-top: none;
    .ant-btn:first-of-type {
      border-color: #c2c7d0;
    }
    .ant-btn-dangerous,
    .ant-btn-dangerous:hover {
      background: #cf3537;
      border-radius: 4px;
      color: white;
    }
  }
}

.select-field-version-date-container {
  position: absolute;
  width: 100%;
  padding-top: 11px;
  z-index: 999;
  > div {
    display: flex;
    justify-content: center;
    .date-container {
      border-radius: 8px 0 0 8px;
      background: rgba(20, 21, 28, 0.5);
      backdrop-filter: blur(5px);
      padding: 10px;
      height: 40px;
      width: 186px;
      align-items: center;
      cursor: pointer;
      .calender-icon {
        img {
          width: 20px;
          height: 20px;
        }
      }
      .date-value {
        font-weight: 600;
        color: #ffffff;
        margin-left: 12px;
        .ant-picker,
        input,
        > div {
          visibility: hidden;
          width: 0px;
          height: 0px;
          padding: 0;
          margin: 0;
        }
      }
    }
    .prev-next-date-icons {
      background: rgba(20, 21, 28, 0.5);
      backdrop-filter: blur(5px);
      border-radius: 0 8px 8px 0;
      width: 64px;
      height: 40px;
      align-items: center;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 40px;
        &:first-child {
          width: 30px;
        }
        &:last-child {
          width: 34px;
        }
        &:hover {
          background: rgba(20, 21, 28, 0.5);
          backdrop-filter: blur(5px);
          border-radius: 8px;
          span::before {
            color: white;
          }
        }
      }
    }
  }
}

.select-date-tooltip {
  .ant-tooltip-inner {
    background: #ffffff;
    border-radius: 4px;
    padding: 6px 10px;
    color: #14151c;
  }
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background: #ffffff;
    }
  }
}

.select-date-calendar {
  top: 20px;
  left: -40px;
  height: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
  width: 0;
}

.show-group-field-page {
  position: fixed;
  top: 68px;
  right: 0;
  z-index: 1;
  max-width: 296px;
  background: rgba(20, 21, 28, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  padding: 8px;
  margin-right: 12px;
  span {
    color: #ffffff !important;
  }
}

.create-fieldgroup-confirm-info {
  cursor: pointer;
  position: fixed;
  z-index: 2;
  width: 258px;
  padding: 4px 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #c2c7d0;
  top: 224px;
  .create-fieldgroup-confirm-info-inner {
    display: flex;
    align-items: center;
    div {
      padding: 4px 0;
    }
  }
  :hover {
    background-color: #f3f4f6;
  }
  span {
    font-weight: 600;
    font-size: 14px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: auto;
  }
  p,
  span {
    line-height: 20px;
    letter-spacing: -0.0075em;
    color: #14151c;
    margin-left: 16px;
  }
}

.create-field-dot-icon {
  cursor: pointer;
  padding: 3px 12px;
  border-radius: 4px;
  margin-left: 4px;
}

.create-field-group-main-container {
  .ant-modal-body {
    padding-bottom: 4px;
  }
  .ant-modal-footer {
    padding-bottom: 24px;
  }
  .create-groupfield-img-div {
    margin-top: 34px;
  }
  .create-groupfield-info-div {
    margin-top: 12px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.0075em;
      color: #14151c;
    }
  }

  .creat-field-groups-imp-info-div {
    margin-top: 12px;
    padding: 12px 16px;
    display: flex;
    background: #fff1f1;
    border: 1px solid #ffb3b3;
    border-radius: 4px;
    .imp-info-text-div {
      margin-left: 14px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.0075em;
        color: #696f88;
      }
    }
  }

  .create-field-group-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .create-button {
      min-width: 156px;
    }
    .cancel-button {
      min-width: 130px;
      background: #ffffff;
      border: 1px solid #c2c7d0;
      color: #14151c;
      &:hover {
        background-color: #f3f4f6;
      }
    }
    .create-button,
    .cancel-button {
      border-radius: 4px;
      align-items: center;
      min-height: 40px;
      margin-top: 4px;
    }
  }
}

.info-circle-outlined-icon {
  font-size: 20px;
  margin-top: 4px;
  color: #eb4b4b;
}

.all-fields-page-mapbox-container {
  z-index: 0;
}

.all-fields-footer-buttons {
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &:last-child {
      margin-left: 32px !important;
    }
  }
}
