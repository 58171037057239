.field-history-card-container {
  padding-left: 4px;
  height: calc(100vh - 54px);
  overflow: hidden;

  .ant-card {
    height: 100%;
    .ant-card-head {
      border-bottom: none;
    }

    .ant-card-head-title {
      padding: 24px 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0075em;
      color: #14151c;
      .timeline-container-title {
        display: flex;
        justify-content: space-between;
        white-space: pre-wrap;
        gap: 10px;
        .timeline-container-title-close-btn {
          cursor: pointer;
        }
      }
    }

    .ant-card-body {
      padding: 0;
      height: 80%;
      overflow-y: auto;

      .timeline-container {
        .timeline-row {
          width: 100%;
          .top-img,
          .bottom-img {
            padding: 0 11px;
          }
          .top-img {
            .add-new-version-btn {
              cursor: pointer;
            }
          }
          .content {
            position: relative;
            display: flex;
            flex-direction: row;
            height: 56px;
            margin: 0;
            align-items: center;
            width: 100%;
            padding: 0 10px;
            cursor: pointer;

            .line-div {
              position: relative;
              padding: 0px 5px;
              height: 100%;
              width: 10px;
              .line {
                height: calc(100% + 4px);
                position: absolute;
                left: 4px;
                border-left: 2px solid #f0f0f0;
                top: -2px;
              }
              .line.isLastItem {
                height: calc(100% - 26px);
              }
              .dot {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
            .text-content {
              width: 100%;
              display: flex;
              align-items: center;
              height: 100%;
              .date-container {
                margin-left: 12px;
                width: 85px;
                .current-version-badge {
                  background: #14803c;
                  border: 1px solid #14803c;
                  box-sizing: border-box;
                  border-radius: 4px;
                  padding: 0 4px;
                  line-height: normal;

                  .ant-badge-status-dot {
                    display: none;
                  }
                  .ant-badge-status-text {
                    margin-left: 0;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #ffffff;
                    text-transform: uppercase;
                  }
                }
              }
              .date-container,
              .name-container {
                display: flex;
                flex-direction: column;
              }
              .img-container {
                margin: 0 10px;
              }
              .name-container .name {
                width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.0075em;
                color: #14151c;
              }
            }
            .more-button-container {
              /* width: 10%; */
              height: 10%;
              padding: 0 0 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: auto;
            }
          }
        }
        .timeline-row.new-version {
          .content {
            background: #eaf6ff;
          }
          .todays-new-version-text {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.0075em;
            color: #868ca2;
          }
        }
      }
    }
  }
}

.edit-field-form-container {
  box-shadow: 0 0 4px #ccc;

  .ant-page-header,
  .ant-page-header-heading {
    width: 100%;
    padding-right: 0;
  }

  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
  }
}

.attension-modal-confirm {
  .attension-modal-confirm-content {
    margin-bottom: 24px;
    .left {
      width: 10%;
      float: left;
      .anticon.anticon-exclamation-circle {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .right {
      width: 90%;
      float: right;
      .attension-confirm-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }
}

.versionControlPopover {
  .ant-popover-content {
    width: 190px;
  }
  .action-buttons {
    padding: 12px 12px 5px 12px;
    .actions-title {
      color: #14151c;
      font-size: 14px;
      cursor: pointer;
      padding-bottom: 6px;
      font-family: 'Noto Sans', sans-serif;
    }
  }

  .ant-popover-arrow {
    right: 11px;
  }
}

.select-version-date-modal {
  .ant-modal-content {
    .ant-modal-footer {
      padding-top: 0;
    }
  }
  .select-version-date-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #14151c;
    margin-bottom: 30px;
  }
  .select-version-date-modal-description {
    .label {
      letter-spacing: -0.0075em;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .ant-modal-footer {
    button:disabled {
      background: #f3f4f6 !important;
      border: 1px solid #dfe2e7;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}

.copy-field-version-modal {
  max-height: calc(100vh - 20px);
  overflow: hidden;
  .ant-modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden;
    .ant-modal-body {
      padding: 24px 0;
      max-height: calc(100vh - 100px);
      overflow: hidden;
      .copy-field-version-modal-title {
        display: flex;
        margin-bottom: 24px;
        padding: 0 24px;
        span:first-child {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
        .copy-field-version-modal-close-icon {
          margin-left: auto;
          cursor: pointer;
        }
      }
      .copy-field-version-modal-description {
        padding: 0 24px;
        max-height: calc(100vh - 150px);
        overflow: auto;
        margin-bottom: 10px;
        .copy-field-version-modal-description-row {
          background: #ffffff;
          border: 1.25217px solid #dfe2e7;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          .copy-field-version-modal-description-row-details {
            padding-left: 12px;
            display: flex;
            flex-direction: column;
            color: #696f88;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.0075em;
            .copy-field-version-modal-description-row-details-name-container {
              display: flex;
              align-items: center;
              .current-version-badge {
                background: #14803c;
                border: 1px solid #14803c;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 4px;
                line-height: normal;
                margin-left: auto;

                .ant-badge-status-dot {
                  display: none;
                }
                .ant-badge-status-text {
                  margin-left: 0;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 16px;
                  color: #ffffff;
                  text-transform: uppercase;
                }
              }
              .copy-field-version-modal-description-row-details-name {
                color: #363948;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.0075em;
              }
            }
          }
        }
        .copy-field-version-modal-description-row.selected {
          background: #f3f4f6;
          border: 1.25217px solid #dfe2e7;
          box-sizing: border-box;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
        }
      }
    }
    .ant-modal-footer {
      button:disabled {
        background: #f3f4f6 !important;
        border: 1px solid #dfe2e7;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }
}
.gemetry-noboundary-img {
  border: 1px solid rgb(232, 234, 237);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
