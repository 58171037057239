.projection-dd-container {
  position: absolute;
  bottom: 10px;
  left: 3%;
  width: 97%;
}

.projection-dd-container .ant-select {
  width: 100%;
}

#files-container .ant-list-item {
  padding-bottom: 40px;
  position: relative;
}

#files-container .ant-list-item-meta {
  margin-bottom: 10px;
  align-items: center;
}

.filelist-icon-container {
  display: flex;
  margin-left: 18px;
  cursor: pointer;
}
